<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">
        Configuring and Upgrading Network and Applications
      </h2>
      <p class="desc">
        Ejaf Technology provides customer-present service and system engineers
        with the assembly of equipment, software setup, and network loading
        details, the essential components of introducing IT services.
      </p>
      <p class="desc">
        Our workers concentrate and cover the construction and remotely
        guarantee that the complete services are linked to the system.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/it-services/computer-specialists-ejaf-tech-canada.jpg"
        alt="Configuring and Upgrading Network and Applications"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-it-services-section",
};
</script>
