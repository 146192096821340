<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">IT Services can help with</h2>
      <ul class="desc">
        <li>
          <p>
            <strong>ROI</strong> growth using our management of IT facilities
          </p>
        </li>
        <li>
          <p>
            <strong>Better</strong> uptime with lower operating prices and
            higher performance
          </p>
        </li>
        <li>
          <p>
            <strong>Controlled</strong> antivirus to defend your desktop along
            with Firewall and Intrusion detection
          </p>
        </li>
        <li>
          <p>
            <strong>Microsoft Patch Management</strong> Program Protect the
            Computer & Databases
          </p>
        </li>
        <li>
          <p><strong>Our Managed Recovery</strong> Tools to Resist Emergency</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/it-services/it-services-eja-tech-canada-1536x1025.jpg"
        alt="IT Services can help with"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fiveth-it-services-section",
};
</script>
