<template>
  <it-services />
</template>

<script>
import ItServices from "../../components/services/ItServices.vue";
export default {
  components: { ItServices },
};
</script>
