<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Connectivity of the Network</h2>
      <p class="desc">
        Instead of simple installation and configuration, the Ejaf system
        enables network integration, which can only be done using modern IT
        services.
      </p>
      <p class="desc">
        We make sure that equipment is correctly configured and that it is
        completely incorporated and running within your network topology. Our
        engineers install all necessary components; the address framework, OOB
        Control, protocols and synchronization of operating systems, including
        applications for network design.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/it-services/network-integration2-ejaf-tech-canada.jpg"
        alt="Connectivity of the Network"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "third-it-services-section",
};
</script>
