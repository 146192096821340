<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">Multivendor Experience</h2>
      <p class="desc">
        Ejaf network infrastructure and system developers have extensive
        knowledge combining network devices from major vendors and can manage
        both the networking and cloud computing of LAN / WAN servers.
      </p>
      <p class="desc">
        We will give our clients one point of touch for the aggregation of
        multivendor, thereby saving time and money.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/it-services/it-servicesimg-3.png"
        alt="Multivendor Experience"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-it-services-section",
};
</script>
