<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">
        Are you annoyed by technological problems?
      </h2>
      <p class="desc">
        With individuals, systems and innovations, Ejaf technology offers
        strategic preparation to incorporate IT services. We provide business
        advisory services and IT services that fulfill the company’s aim for
        profitable development and competitive advantage and bring value and
        consumers. With our unmatched experience, we suggest new technological
        approaches and validated activities.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/it-services/create-data-center.png"
        alt="Are you annoyed by technological problems?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-it-services-section",
};
</script>
